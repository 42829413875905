import React, { useEffect } from "react";
import Layout from "../components/layout";
import SectionHeader from "../components/sectionHeader";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import enrolIcon from "../assets/icons/icon-enrol.png";
import { Container, Tabs, Tab, Col, Row } from "react-bootstrap";

function ArticlePage({ data }) {
  const article = data.strapiArticle;

  const seo = {
    metaTitle: article.Title,
  };

  return (
    <Layout>
      <Helmet title={seo.metaTitle} defer={false} />
      <div className="container">
        <div className="page-banner">
          <img
            src={
              article.articleHeaderImage && article.articleHeaderImage.publicURL
            }
            className="img-fluid"
          />
        </div>
      </div>
      <Container className="publications container">
        <Row style={{ alignItems: "center" }}>
          <Col md={12}>
            <SectionHeader
              icon={enrolIcon}
              title={article.Title}
              highlight={article.TitleHighlight}
              text={article.Content}
            />

            <div>
              <video width="100%" controls>
                <source
                  src={article.video && article.video.publicURL}
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="row mt-5">
              <div className="col-7">
                <span
                  dangerouslySetInnerHTML={{
                    __html: article.image_right && article.image_right.content,
                  }}
                />
              </div>
              <div className="col-5">
                <img
                  src={
                    article.image_right && article.image_right.image.publicURL
                  }
                  className="img-fluid"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
export const pageQuery = graphql`
  query ($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      id
      image_right {
        content
        image {
          publicURL
        }
      }
      slug
      created_at
      paragraph
      video {
        publicURL
      }
      articleHeaderImage {
        publicURL
      }
      Content
      Description
      Title
    }
  }
`;
export default ArticlePage;
